import { Block, BlockCategory } from './types'

import { uniqueId } from './utils'

export const blockCategories: BlockCategory[] = [
    {
        id: uniqueId(),
        name: 'Data Source',
        color: '#f6f6f6',
    },
    {
        id: uniqueId(),
        name: 'Logic',
        color: '#f6f6f6',
    },
    {
        id: uniqueId(),
        name: 'Data Destination',
        color: '#f6f6f6',
    },
]

export const blocks: Block[] = [
    {
        id: uniqueId(),
        categoryId: blockCategories[0].id,
        color: blockCategories[0].color,
        blockType: 'output',
        name: 'SalesForce',
        icon: 'FunctionOutlined',
        ports: [
            {
                id: uniqueId(),
                type: 'output',
            },
        ],
    },
    {
        id: uniqueId(),
        categoryId: blockCategories[0].id,
        color: blockCategories[0].color,
        blockType: 'output',
        name: 'SFTP',
        icon: 'FunctionOutlined',
        ports: [
            {
                id: uniqueId(),
                type: 'output',
            },
        ],
    },
    {
        id: uniqueId(),
        categoryId: blockCategories[0].id,
        color: blockCategories[0].color,
        blockType: 'output',
        name: 'SQL Database',
        icon: 'FunctionOutlined',
        ports: [
            {
                id: uniqueId(),
                type: 'output',
            },
        ],
    },
    {
        id: uniqueId(),
        categoryId: blockCategories[1].id,
        color: blockCategories[1].color,
        blockType: 'output',
        name: 'Logical template',
        icon: 'FunctionOutlined',
        ports: [
            {
                id: uniqueId(),
                type: 'input',
            },
            {
                id: uniqueId(),
                type: 'output',
            },
        ],
    },
    {
        id: uniqueId(),
        categoryId: blockCategories[2].id,
        color: blockCategories[2].color,
        blockType: 'output',
        name: 'SalesForce',
        icon: 'HomeOutlined',
        ports: [
            {
                id: uniqueId(),
                type: 'input',
            },
        ],
    },
    {
        id: uniqueId(),
        categoryId: blockCategories[2].id,
        color: blockCategories[2].color,
        blockType: 'output',
        name: 'SFTP',
        icon: 'HomeOutlined',
        ports: [
            {
                id: uniqueId(),
                type: 'input',
            },
        ],
    },
    {
        id: uniqueId(),
        categoryId: blockCategories[2].id,
        color: blockCategories[2].color,
        blockType: 'default',
        name: 'BigQuery Google',
        icon: 'HomeOutlined',
        ports: [
            {
                id: uniqueId(),
                type: 'input',
            },
        ],
    },
    {
        id: uniqueId(),
        categoryId: blockCategories[2].id,
        color: blockCategories[2].color,
        blockType: 'default',
        name: 'Hubspot CRM',
        icon: 'HomeOutlined',
        ports: [
            {
                id: uniqueId(),
                type: 'input',
            },
        ],
    },
    {
        id: uniqueId(),
        categoryId: blockCategories[2].id,
        color: blockCategories[2].color,
        blockType: 'default',
        name: 'AWS Data',
        icon: 'HomeOutlined',
        ports: [
            {
                id: uniqueId(),
                type: 'input',
            },
        ],
    },
]
